export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "detailHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Detail"])},
        "oppNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paymentFailure Number"])},
        "productOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Option"])},
        "excess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excess"])},
        "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
        "driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver"])},
        "compulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compulsory"])},
        "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
        "activateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated Date"])},
        "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "brokerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broker Name"])},
        "agentEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent email"])},
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
        "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
        "subModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Model"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years of manufacture"])},
        "carPlate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car Plate"])},
        "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "custInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Information"])},
        "custName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "custMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
        "custEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "businessValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business validate"])},
        "notPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Pass"])},
        "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium"])},
        "activatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated date"])},
        "fullPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full"])},
        "12monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 months installment"])},
        "10monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 months installment with deposit"])},
        "10monthsInstallmentNoDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 months installment"])},
        "4monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 months installment"])},
        "paymentPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Plan"])},
        "overdueInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue installment"])},
        "installmentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment number"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "paymentInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Information"])},
        "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method"])},
        "paymentFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
        "installmentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment number"])},
        "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
        "paymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Date"])},
        "paymentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "netAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Amount"])}
      },
      "th": {
        "detailHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดการชำระเงิน"])},
        "oppNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลขใบเสนอราคา"])},
        "productOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวเลือกผลิตภัณฑ์"])},
        "excess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าเสียหายส่วนแรก"])},
        "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อู่"])},
        "driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้ขับขี่"])},
        "compulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พ.ร.บ"])},
        "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่สร้าง"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันเริ่มกรมธรรม์"])},
        "activateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ปิดการขาย"])},
        "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะใบเสนอ"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะ"])},
        "brokerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โบรกเกอร์"])},
        "agentEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล์ที่ปรึกษา"])},
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลิตภัณฑ์"])},
        "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยี่ห้อ"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รุ่น"])},
        "subModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดรุ่น"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปีที่ผลิต"])},
        "carPlate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ป้ายทะเบียน"])},
        "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันครบกำหนด"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวน"])},
        "custInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดข้อมูลลูกค้า"])},
        "custName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อลูกค้า"])},
        "custMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบอร์โทรศัพท์"])},
        "custEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล์"])},
        "businessValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การรับประกัน"])},
        "notPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ผ่าน"])},
        "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบี้ยประกันภัย"])},
        "activatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ปิดการขาย"])},
        "fullPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จ่ายเต็มจำนวน"])},
        "12monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่อน 12 งวด"])},
        "10monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่อน 10 งวด (มีมัดจำ)"])},
        "10monthsInstallmentNoDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่อน 10 งวด"])},
        "4monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่อน 4 งวด"])},
        "paymentPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แผนการชำระเงิน"])},
        "overdueInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยอดที่ค้างชำระ"])},
        "installmentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["งวดที่"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลิงก์"])},
        "paymentInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดการชำระเงิน"])},
        "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิธีการชำระเงิน"])},
        "paymentFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การชำระเงิน"])},
        "installmentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment number"])},
        "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันครบกำหนด"])},
        "paymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Date"])},
        "paymentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "netAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Amount"])}
      },
      "zh": {
        "detailHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Detail"])},
        "oppNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["机会号码"])},
        "productOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Option"])},
        "excess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超额部分"])},
        "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["维修店铺"])},
        "driver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["司机"])},
        "compulsory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["强制性的"])},
        "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建日期"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始日期"])},
        "activateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated Date"])},
        "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
        "brokerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经纪人姓名"])},
        "agentEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理邮箱"])},
        "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品"])},
        "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["型号"])},
        "subModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子型号"])},
        "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生产年份"])},
        "carPlate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车牌"])},
        "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "custInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户资料"])},
        "custName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "custMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户手机号码"])},
        "custEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户邮箱"])},
        "businessValidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商业验证"])},
        "notPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不通过"])},
        "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保费"])},
        "activatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated date"])},
        "fullPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full"])},
        "12monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 months installment"])},
        "10monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 months installment with deposit"])},
        "10monthsInstallmentNoDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 months installment"])},
        "4monthsInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 months installment"])},
        "paymentPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Plan"])},
        "overdueInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue installment"])},
        "installmentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment number"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接"])},
        "paymentInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Information"])},
        "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method"])},
        "paymentFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
        "installmentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment number"])},
        "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
        "paymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Date"])},
        "paymentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "netAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Amount"])}
      }
    }
  })
}
